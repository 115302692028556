import { FC, MouseEventHandler } from 'react';
import {
  Box,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { featuresItems } from '../constants';
import { Icon } from 'ui';

export const FeaturesBlockMobile: FC<{
  activeItem: number;
  handleClick: (newActiveId: number) => MouseEventHandler;
}> = ({ activeItem, handleClick }) => {
  const { t } = useTranslation();

  return (
    <Container id="features">
      <Grid container rowSpacing={'30px'}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="overline">
            {t('features.headerText')}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              mb: 1,
              mt: 1.5,
            }}
          >
            {t('features.title')}
          </Typography>
          <Typography color="grey.300" variant="body2">
            {t('features.subTitle')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            src={`/images/Img-phone-wal-${activeItem}.png`}
            alt={`features-${activeItem}`}
            width={306}
            height={342.25}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            '&.MuiGrid-root': {
              paddingTop: '39px',
            },
          }}
          rowSpacing={'39px'}
          container
        >
          {featuresItems.map(({ id, icon, title }) => (
            <Grid item xs={6} key={id}>
              <ListItem
                alignItems="center"
                onClick={handleClick(id)}
                sx={{
                  '&.MuiListItem-root': {
                    cursor: 'pointer',
                    padding: 0,
                    flexDirection: 'column',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',

                    '&:hover': {
                      '& .rectangle-colored': {
                        bgcolor: 'primary.main',
                      },

                      '& .colored-icon': {
                        color: 'common.white',
                      },
                    },
                  },
                }}
              >
                <ListItemAvatar
                  sx={{
                    '&.MuiListItemAvatar-root': {
                      minWidth: '73px',
                      marginBottom: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        top: '5px',
                        left: '3px',
                        zIndex: 3,
                      }}
                    >
                      <Icon
                        name={icon}
                        className="colored-icon"
                        sx={{
                          fontSize: 24,
                          color: activeItem === id ? 'common.white' : 'grey.400',
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        border: '1px solid #000000',
                        borderRadius: '12px',
                        transform: 'rotate(45deg)',
                        position: 'absolute',
                        left: 3,
                        top: 2,
                        zIndex: 2,
                      }}
                    />

                    <Box
                      className="rectangle-colored"
                      sx={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '12px',
                        transform: 'rotate(45deg)',
                        position: 'absolute',
                        left: 0,
                        top: 3,
                        zIndex: 1,
                        bgcolor:
                          activeItem === id
                            ? 'primary.main'
                            : 'linear-gradient(131.15deg, #F3E8FF 10.57%, #F4F6FB 40.8%, #F0F0FF 84.45%)',
                      }}
                    />
                  </Box>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    '&.MuiListItemText-root': {
                      maxWidth: '145px',
                      textAlign: 'center',
                    },
                  }}
                  primary={
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      sx={{
                        mb: 0.5,
                      }}
                    >
                      {t(title)}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
