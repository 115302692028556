import { FC } from 'react';
import { Box, Button, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { Icon, IconName } from 'ui';
import { MainBlockMobile } from './MainBlockMobile';
import { links } from './constants';
import { LangSelect } from './LangSelect';

const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;

export const MainBlock: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const matches = useMediaQuery('(min-width:1284px)');

  const handleDesktopClick = (device: string) => () => {
    if (MEASUREMENT_ID) {
      ReactGA.event({
        category: 'Desktop',
        action: 'Click app button',
        label: device,
      });
    }
  };

  if (!matches) {
    return <MainBlockMobile />;
  }

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid
          item
          xs={7}
          sx={{
            paddingTop: 2.75,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              maxWidth: 753,
              width: '100%',
            }}
          >
            <Box>
              <Box component="img" src="/images/Logo.svg" alt="logo" />
            </Box>

            <Box
              sx={{
                paddingTop: 20.25,
                maxWidth: 610,
              }}
            >
              <Typography variant="overline" color="secondary">
                {t('headerContent.headerText')}
              </Typography>

              <Typography
                variant="h1"
                sx={{
                  mt: 1.75,
                }}
              >
                <Trans
                  i18nKey="headerContent.title"
                  components={{
                    typography: (
                      <Typography
                        variant="h1"
                        color="primary"
                        sx={{
                          display: 'inline',
                        }}
                      />
                    ),
                  }}
                />
              </Typography>

              <Typography
                sx={{
                  pr: 8.75,
                  mt: 2,
                  lineHeight: '32px',
                }}
                color="text.primary"
                variant="body1"
              >
                {t('headerContent.subTitle')}
              </Typography>

              <Box
                sx={{
                  marginTop: '25px',
                }}
              >
                <Grid container spacing={3}>
                  <Grid item>
                    <Button
                      target="_blank"
                      component={Link}
                      href={process.env.REACT_APP_APP_STORE}
                      onClick={handleDesktopClick('ios_desktop')}
                      startIcon={<Icon name={IconName.apple} />}
                      variant="contained"
                      sx={{
                        '&.MuiButton-root': {
                          borderRadius: '8px',
                          padding: '12px 16px',
                          bgcolor: 'common.black',
                        },

                        '& .MuiButton-startIcon ': {
                          marginLeft: '-23px',

                          '& > *:nth-of-type(1)': {
                            fontSize: '28px',
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: 'initial',
                          }}
                        >
                          Get it on
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          App Store
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      target="_blank"
                      component={Link}
                      href={process.env.REACT_APP_PLAY_MARKET}
                      onClick={handleDesktopClick('android_desktop')}
                      startIcon={
                        <Box component="img" src="/images/Playmarket.svg" alt="playmarket" />
                      }
                      variant="contained"
                      sx={{
                        '&.MuiButton-root': {
                          borderRadius: '8px',
                          padding: '12px 16px',
                          bgcolor: 'common.black',
                        },

                        '& .MuiButton-startIcon ': {
                          marginLeft: '-8px',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: 'initial',
                          }}
                        >
                          Get it on
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          Google Play
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  flexDirection="column"
                  sx={{
                    marginTop: '25px',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: 12,
                      letterSpacing: '0.4px',
                    }}
                  >
                    {t('headerContent.scanToInstainstall')}
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: '120px',
                    }}
                    component="img"
                    src="/images/qr-code.png"
                    alt="qr"
                  />
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={5}
          sx={{
            background: '#000',
            height: 980,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
            }}
          >
            <LangSelect color="dark" />
          </Box>
          <Box
            sx={{
              maxWidth: 475,
            }}
          >
            <Box
              sx={{
                paddingTop: 3.25,
                paddingLeft: 2.75,
                typography: 'body1',
                '& > :not(style) + :not(style)': {
                  ml: 4,
                },
              }}
            >
              {links.map(({ id, label, href }) => (
                <Link
                  key={id}
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                  href={`#${href}`}
                >
                  {t(label)}
                </Link>
              ))}
            </Box>
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 102,
                  left: -56,
                  zIndex: 4,
                  [theme.breakpoints.down(1370)]: {
                    left: -86,
                  },
                }}
                component="img"
                src="/images/app-screen.png"
                alt="app-screen"
                role="img"
                width={610}
                height={642}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 51,
                  left: 168,
                  [theme.breakpoints.down(1370)]: {
                    left: 138,
                  },
                }}
                component="img"
                src="/images/Rectangle.svg"
                alt="Rectangle"
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 63,
                  left: 113,
                  zIndex: 3,
                  [theme.breakpoints.down(1370)]: {
                    left: 83,
                  },
                }}
                component="img"
                src="/images/Ellipse.png"
                alt="Ellipse"
              />

              <Box
                sx={{
                  position: 'absolute',
                  top: 356,
                  left: 375,
                  [theme.breakpoints.down(1370)]: {
                    left: 345,
                  },
                }}
                component="img"
                src="/images/Rhombus.svg"
                alt="Rhombus"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
