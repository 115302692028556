import { GridDirection, SxProps, Theme, TypographyProps } from '@mui/material';

export const content: {
  id: number;
  image: string;
  desktopDimensions: {
    width: number;
    height: number;
  };
  mobileImage: string;
  mobileDimensions: {
    width: number;
    height: number;
  };
  alt: string;
  headerText: string;
  title: string;
  titleColor?: TypographyProps['color'];
  titleWidth?: string;
  content: string;
  imageSx?: SxProps<Theme>;
  mobileImageStyles?: SxProps<Theme>;
  direction?: GridDirection;
}[] = [
  {
    id: 1,
    image: '/images/Users.png',
    desktopDimensions: {
      width: 384,
      height: 384,
    },
    mobileImage: '/images/Users-mobile.png',
    mobileDimensions: {
      width: 304,
      height: 304,
    },
    alt: 'users',
    headerText: 'advantage.users.headerText',
    title: 'advantage.users.title',
    content: 'advantage.users.subTitle',
    direction: 'row-reverse',
    imageSx: {
      paddingLeft: '30px',
    },
  },
  {
    id: 2,
    image: '/images/cards.png',
    desktopDimensions: {
      width: 391,
      height: 397,
    },
    mobileImage: '/images/cards-mobile.png',
    mobileDimensions: {
      width: 381,
      height: 398,
    },
    alt: 'cards',
    headerText: 'advantage.limits.headerText',
    titleColor: 'secondary',
    titleWidth: '489px',
    title: 'advantage.limits.title',
    content: 'advantage.limits.subTitle',
    imageSx: {
      justifyContent: 'flex-end',
      paddingRight: '46px',
    },
    mobileImageStyles: {
      transform: 'translate(28px, 0)',
      marginTop: '-58px',
    },
  },
];
