import { FC, MouseEventHandler, useCallback, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { featuresItems } from './constants';
import { Icon } from 'ui';
import { FeaturesBlockMobile } from './FeaturesBlockMobile';

export const FeaturesBlock: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [activeItem, setActiveItem] = useState(1);

  const handleHover = useCallback<(newActiveId: number) => MouseEventHandler>(
    (newActiveId) => () => {
      if (newActiveId !== activeItem) {
        setActiveItem(newActiveId);
      }
    },
    [activeItem],
  );

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  if (!matches) {
    return <FeaturesBlockMobile activeItem={activeItem} handleClick={handleHover} />;
  }

  return (
    <Container id="features">
      <Grid container>
        <Grid
          item
          xs={8}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '100px',
          }}
        >
          <Grid
            container
            sx={{
              maxWidth: '602px',
              width: '100%',
            }}
          >
            <Grid item xs={12}>
              <Typography color="primary.main" variant="overline">
                {t('features.headerText')}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                  mt: 1,
                }}
              >
                {t('features.title')}
              </Typography>
              <Typography
                color="grey.300"
                variant="body2"
                sx={{
                  lineHeight: '18.75px',
                }}
              >
                {t('features.subTitle')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              columnSpacing={3}
              rowSpacing={2}
              sx={{
                paddingTop: '20px',
              }}
            >
              {featuresItems.map(({ id, icon, title, subTitle }) => (
                <Grid item xs={6} key={id}>
                  <ListItem
                    alignItems="flex-start"
                    onMouseEnter={handleHover(id)}
                    sx={{
                      '&.MuiListItem-root': {
                        cursor: 'pointer',
                        padding: 0,
                        paddingLeft: '8px',

                        '&:hover': {
                          '& .rectangle-colored': {
                            bgcolor: 'primary.main',
                          },

                          '& .colored-icon': {
                            color: 'common.white',
                          },
                        },
                      },
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        '&.MuiListItemAvatar-root': {
                          minWidth: '73px',
                          marginRight: 1,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: '50px',
                          height: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            top: '5px',
                            left: '3px',
                            zIndex: 3,
                          }}
                        >
                          <Icon
                            name={icon}
                            className="colored-icon"
                            sx={{
                              fontSize: 24,
                              color: activeItem === id ? 'common.white' : 'grey.400',
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: '50px',
                            height: '50px',
                            border: '1px solid #000000',
                            borderRadius: '12px',
                            transform: 'rotate(45deg)',
                            position: 'absolute',
                            left: 3,
                            top: 2,
                            zIndex: 2,
                          }}
                        />

                        <Box
                          className="rectangle-colored"
                          sx={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '12px',
                            transform: 'rotate(45deg)',
                            position: 'absolute',
                            left: 0,
                            top: 3,
                            zIndex: 1,
                            bgcolor:
                              activeItem === id
                                ? 'primary.main'
                                : 'linear-gradient(131.15deg, #F3E8FF 10.57%, #F4F6FB 40.8%, #F0F0FF 84.45%)',
                          }}
                        />
                      </Box>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        '&.MuiListItemText-root': {
                          maxWidth: '198px',
                        },
                      }}
                      primary={
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          sx={{
                            mb: 0.5,
                          }}
                        >
                          {t(title)}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="grey.300">
                          {t(subTitle)}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '15px',
            paddingRight: '4px',
          }}
        >
          <Box
            component="img"
            src={`/images/Features${activeItem}.png`}
            alt={`features-${activeItem}`}
            width={371}
            height={471}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
