import { FC, useMemo } from 'react';

import { get } from 'lodash';
import { Icon as MUIIcon } from '@mui/material';

import { IconProps, TRenderPath } from './types';
import { coloredIconsLength } from './constants';

export const Icon: FC<IconProps> = ({ name, className = '', ...rest }) => {
  const renderPathArr = useMemo<TRenderPath>(
    () => (arrLength) => {
      // create array with chosen length with empty strings
      // do not remove .fill('') because map will not work
      const spanArr = new Array(arrLength).fill('');

      return spanArr.map((_, index) => {
        const key = index + 1;

        return <span key={key} className={`path${key}`}></span>;
      });
    },
    [],
  );

  // need for render colored icons
  const renderChildren = useMemo(() => {
    // length depend on each icon, u can check in graphics/icons/style.css file
    const childrenLength: null | number = get(coloredIconsLength, name as string, null);

    if (childrenLength) {
      return renderPathArr(childrenLength);
    }

    return childrenLength;
  }, [name, renderPathArr]);

  return (
    <MUIIcon className={`icon-${name} ${className}`} {...rest}>
      {renderChildren}
    </MUIIcon>
  );
};
