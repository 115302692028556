import { FC } from 'react';
import { FormControl, MenuItem, Select, SelectProps } from '@mui/material';
import { Icon, IconName } from 'ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { find } from 'lodash';

const lang = [
  {
    id: 'ua',
    icon: IconName.Ukraine,
    linkTo: '/',
  },
  {
    id: 'en',
    icon: IconName.UnitedKingdom,
    linkTo: '/en',
  },
];

export const LangSelect: FC<{
  color?: 'dark' | 'light';
}> = ({ color = 'light' }) => {
  const { i18n } = useTranslation();

  const changeLanguage: SelectProps<string>['onChange'] = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <FormControl
      variant="standard"
      sx={{
        '& .MuiInputBase-root.MuiInput-root': {
          '&:after': {
            display: 'none',
          },

          '&:hover:before, &:before': {
            borderBottom: 'none',
          },

          '& .MuiSvgIcon-root': {
            color: color === 'dark' ? 'common.white' : 'common.black',
          },

          '& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input': {
            pb: 0,
            pt: 0,
          },
        },
      }}
    >
      <Select<string>
        MenuProps={{
          disablePortal: true,
        }}
        onChange={changeLanguage}
        size="small"
        value={i18n.language}
        renderValue={(currentValue) => {
          const currentIcon = find(lang, { id: currentValue });

          return <Icon name={currentIcon?.icon} />;
        }}
      >
        {lang.map(({ id, icon, linkTo }) => (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <MenuItem<any> key={id} value={id} component={Link} to={linkTo}>
            <Icon name={icon} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
