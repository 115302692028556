import { createTheme, Theme } from '@mui/material';

const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

// A custom theme for this app
const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#FF4081',
    },
    secondary: {
      main: '#2196F3',
    },
    text: {
      primary: '#18191F',
      secondary: '#000000',
    },
    grey: {
      50: '#F4F0F8',
      100: '#DDDDDD',
      200: '#B7B7B7',
      300: '#636366',
      400: '#545454',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        'body, html': {
          height: '100%',
          width: '100%',
        },
        '#root': {
          background:
            'radial-gradient(28.08% 10.51% at 56.18% 78.15%, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(29.33% 43.43% at 51.63% 39.34%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(177.58deg, #FFF4F8 4.22%, #EFF9FF 12.84%, #F3EEFF 21.27%, #FAF8FF 28.66%, #FFFFFF 33.66%, #E2F5FF 41.32%, #FFFAFD 48.91%, #F4FCFF 63.79%, #F6F3FF 78.29%, #FFFFFF 97.49%)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: '0.5px',
        },
        h1: {
          fontSize: 64,
          fontWeight: 600,
          lineHeight: '140%',
          [breakpoints.down('md')]: {
            fontSize: 30,
            lineHeight: '130%',
          },
        },
        h2: {
          fontSize: 38,
          fontWeight: 500,
          lineHeight: '140%',
          letterSpacing: '0.5px',
          [breakpoints.down('md')]: {
            fontSize: 28,
          },
        },
        h3: {},
        h6: {
          fontSize: 20,
          fontWeight: 400,
          lineHeight: '140%',
        },
        body1: {
          fontSize: 18,
          fontWeight: 400,
          lineHeight: '140%',
        },
        body2: {
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '140%',
        },
        subtitle1: {
          fontSize: 24,
          fontWeight: 400,
          lineHeight: '140%',
        },
        subtitle2: {
          fontSize: 16,
          fontWeight: 500,
          lineHeight: '140%',
        },
        overline: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '140%',
        },
        caption: {
          fontSize: '10px',
          fontWeight: 400,
          lineHeight: '140%',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          border: '1px solid #EEEEEE',
          boxShadow: '0px 16px 16px rgba(0, 0, 0, 0.02)',

          '&:hover': {
            boxShadow: '14px 16px 24px rgba(0, 0, 0, 0.06)',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 30px 24px',
          textAlign: 'center',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '172px',
          padding: '13px 16px',
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '30px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [breakpoints.up('lg')]: {
            maxWidth: 1224,
            padding: 0,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontSize: '16px',
          lineHeight: '140%',
          color: '#DDDDDD',
          fontWeight: 400,
        },
      },
    },
  },
});

export default theme;
