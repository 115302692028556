import { FC } from 'react';
import { Box, Card, CardContent, Container, Grid, Typography, useTheme } from '@mui/material';
import { Icon, IconName } from 'ui';
import { useTranslation } from 'react-i18next';
import { cardsContent } from './constants';

export const AboutBlock: FC = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Container>
      <Grid
        container
        columnSpacing={{
          sm: 3,
          md: 3,
          lg: 9,
        }}
        rowSpacing={{
          sm: 3,
          md: 3,
          lg: 9,
        }}
        justifyContent="center"
        sx={{
          pt: 15.5,
          pb: 14.5,

          [theme.breakpoints.down('md')]: {
            paddingTop: '46px',
            paddingBottom: '103px',
          },
        }}
      >
        {cardsContent.map(({ id, title, content, icon }) => (
          <Grid item key={id}>
            <Card
              sx={{
                width: (theme) => theme.spacing(36.375),
                [theme.breakpoints.down('md')]: {
                  maxWidth: '284px',
                  width: '100%',
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
            >
              <CardContent
                sx={{
                  [theme.breakpoints.down('md')]: {
                    '&:last-child': {
                      paddingBottom: '27px',
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: (theme) => theme.spacing(10),
                      height: (theme) => theme.spacing(10),
                      borderRadius: '50%',
                      background:
                        'linear-gradient(131.15deg, #F3E8FF 10.57%, #F4F6FB 40.8%, #F0F0FF 84.45%)',
                    }}
                  >
                    <Icon
                      name={icon}
                      sx={{
                        overflow: 'visible',
                        fontSize: (theme) =>
                          icon === IconName.cloudadd1 ? theme.spacing(5) : theme.spacing(4.25),
                      }}
                    />
                  </Box>
                </Box>

                <Typography
                  sx={{
                    mt: 1.5,
                  }}
                  variant="subtitle1"
                >
                  {t(title)}
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                  }}
                  variant="body2"
                  color="grey.400"
                >
                  {t(content)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
