import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import App from 'App';
import { NotFound } from 'components/NotFound';

const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;

if (MEASUREMENT_ID) {
  ReactGA.initialize(MEASUREMENT_ID);
}

function RouterApp(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:locale?" element={<App />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RouterApp;
