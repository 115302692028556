import { ITranslateResource } from 'i18n/types';

const enTranslations: ITranslateResource = {
  navigation: {
    advantages: 'Advantages',
    features: 'Features',
    howItWorks: 'How it works',
    contactUs: 'Contact Us',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
  },
  headerContent: {
    headerText: 'Manage your budget simply',
    title: 'Plan Expenses <br /> and Stay Clear With <br /> Your <typography>Money</typography>',
    titleMobile: 'Plan Expenses and Stay Clear With Your <typography>Money</typography>',
    subTitle: 'An application that will help you in planning, controlling and optimizing expenses',
    scanToInstainstall: 'Scan to instainstall',
  },
  button: {
    getStarted: 'Get started',
  },
  about: {
    functional: {
      title: 'Functional',
      content: 'Many useful functions to optimize your budget',
    },
    easy: {
      title: 'Easy',
      content: 'Easy and clear use of application functions',
    },
    free: {
      title: 'Free',
      content: 'Absolutely free to use. Just try and enjoy!',
    },
  },
  advantage: {
    users: {
      headerText: 'Your people',
      title: 'Create Joint Budgets With Family and Friends',
      subTitle:
        'You have the opportunity to create any number of budgets and add as many people as you want! Just add participants and manage the budget together',
    },
    limits: {
      headerText: 'No limits',
      title: 'Create Any Number of Wallets and Categories',
      subTitle:
        'Create as many wallets and categories as you need. You can create categories and set a budget to help you plan and control your spending.',
    },
  },
  features: {
    headerText: 'Great functions',
    title: 'Convenient Features for You',
    subTitle:
      'The Right Budget mobile application was created to facilitate the management of personal and joint budgets. Its main functions:',
    items: {
      1: {
        title: 'Unlimited Accounts',
        subTitle: 'Unlimited number of accounts',
      },
      2: {
        title: 'Unlimited Categories',
        subTitle: 'Create a budget for each category',
      },
      3: {
        title: 'Shared budgets',
        subTitle: 'Shared budgets with unlimited users',
      },
      4: {
        title: 'Shopping Lists',
        subTitle: 'Plan your shopping and share lists with other',
      },
      5: {
        title: 'Statistic & History',
        subTitle: 'Simple and clear statistics and transaction history',
      },
      6: {
        title: 'Easy Workspaces',
        subTitle: 'Manage your personal and shared workspaces',
      },
    },
  },
  steps: {
    title: 'Your ways',
    subTitle: 'Three simple steps to budgeting',
    stepper: {
      1: {
        title: 'Plan your budget',
        subTitle: 'Create any number of categories and plan expenses for the month',
      },
      2: {
        title: 'Control expenses',
        subTitle:
          'Follow the transactions in the history and be aware of the movement of your money',
      },
      3: {
        title: 'Analyze and improve',
        subTitle: 'See statistics for months and years and optimize expenses',
      },
    },
  },
  cta: {
    title: 'Right Budget is absolutely free',
    content:
      'We help you plan and control your expenses, not add new ones. Therefore, the application is free for all users.',
  },
};

export default enTranslations;
