import { ReactElement } from 'react';

import { IconProps as MUIIconProps } from '@mui/material';

export type IconProps = MUIIconProps & {
  name?: IconName;
};

export type TRenderPath = (pathLenght: number) => ReactElement[];

export enum IconName {
  apple = 'apple',
  copysuccess = 'copysuccess',
  category = 'category',
  note = 'note',
  convertshape2 = 'convertshape2',
  cards = 'cards',
  chart21 = 'chart21',
  arrowcircleleft = 'arrowcircleleft',
  arrowcircleright = 'arrowcircleright',
  shieldtick = 'shieldtick',
  like1 = 'like1',
  documentfilter = 'documentfilter',
  colorfilter = 'colorfilter',
  cloudadd = 'cloudadd',
  cloudadd1 = 'cloudadd1',
  emojihappy = 'emojihappy',
  clipboardtext = 'clipboardtext',
  chart1 = 'chart1',
  statusup = 'statusup',
  elementplus = 'elementplus',
  Ukraine = 'Ukraine',
  UnitedKingdom = 'United-Kingdom',
}
