import { FC } from 'react';
import {
  Box,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { steps } from './constants';

export const StepsBlock: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Container id="howItWorks">
      <Grid
        sx={{
          mt: 17.75,
          mb: 14.5,

          [theme.breakpoints.down('lg')]: {
            marginTop: '82px',
            marginBottom: '46px',
          },
        }}
        container
      >
        <Grid
          item
          xs={12}
          sx={{
            paddingBottom: '56px',

            [theme.breakpoints.down('lg')]: {
              paddingBottom: '29px',
            },
          }}
        >
          <Typography
            sx={{
              display: 'block',
              mb: 1,
            }}
            variant="overline"
            color="secondary"
            align={!matches ? 'inherit' : 'center'}
          >
            {t('steps.title')}
          </Typography>
          <Typography
            variant="h2"
            align={!matches ? 'inherit' : 'center'}
            sx={{
              letterSpacing: '0.064px',
              textTransform: 'capitalize',
            }}
          >
            {t('steps.subTitle')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{
            [theme.breakpoints.down('lg')]: {
              justifyContent: 'space-around',
            },
          }}
        >
          {matches && (
            <Grid
              item
              md={12}
              lg={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                component="img"
                src={'/images/Statistic.png'}
                alt="statistic"
                width={386}
                height={358}
              />
            </Grid>
          )}
          <Grid
            item
            md={12}
            lg={6}
            sx={{
              paddingTop: '11px',
              paddingLeft: '19px',

              [theme.breakpoints.down('lg')]: {
                display: 'flex',
                justifyContent: 'center',
                padding: 0,
              },
            }}
          >
            <Stepper
              activeStep={0}
              orientation="vertical"
              sx={{
                '& .MuiStepConnector-root': {
                  marginLeft: '24px',
                  position: 'relative',
                  minHeight: 52,

                  [theme.breakpoints.down('lg')]: {
                    minHeight: 36,
                  },
                },
                '& .MuiStepConnector-line': {
                  minHeight: 85,
                  position: 'absolute',
                  top: -14,

                  [theme.breakpoints.down('lg')]: {
                    top: -28,
                    minHeight: 115,
                  },
                },
              }}
            >
              {steps.map(({ id, title, subTitle }) => (
                <Step key={id}>
                  <StepLabel
                    optional={
                      <Typography variant="body2" color="grey.400">
                        {t(subTitle)}
                      </Typography>
                    }
                    sx={{
                      '& .MuiStepIcon-root': {
                        color: 'grey.50',

                        '&.Mui-active': {
                          color: 'primary.main',

                          '& .MuiStepIcon-text': {
                            fill: (theme) => theme.palette.common.white,
                          },
                        },
                      },

                      '&.MuiStepLabel-root': {
                        padding: 0,

                        '& .MuiStepIcon-text': {
                          fill: (theme) => theme.palette.text.secondary,
                          fontSize: 10,
                        },

                        '& .MuiStepLabel-iconContainer': {
                          paddingRight: '24px',
                          zIndex: 1,

                          [theme.breakpoints.down('lg')]: {
                            paddingRight: '16px',
                          },
                        },

                        '& .MuiSvgIcon-root': {
                          width: 48,
                          height: 48,
                        },

                        '& .MuiStepLabel-labelContainer': {
                          maxWidth: '331px',
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      sx={{
                        mb: '2px',
                      }}
                    >
                      {t(title)}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          {!matches && (
            <Grid
              item
              md={12}
              lg={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '32px',
              }}
            >
              <Box
                component="img"
                src={'/images/Statistics-chart-mobile.png'}
                alt="statistic"
                width={293}
                height={334}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
