export const steps: {
  id: number;
  title: string;
  subTitle: string;
}[] = [
  {
    id: 1,
    title: 'steps.stepper.1.title',
    subTitle: 'steps.stepper.1.subTitle',
  },
  {
    id: 2,
    title: 'steps.stepper.2.title',
    subTitle: 'steps.stepper.2.subTitle',
  },
  {
    id: 3,
    title: 'steps.stepper.3.title',
    subTitle: 'steps.stepper.3.subTitle',
  },
];
