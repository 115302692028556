import { IconName } from 'ui';

export const cardsContent = [
  {
    id: 1,
    icon: IconName.elementplus,
    title: 'about.functional.title',
    content: 'about.functional.content',
  },
  {
    id: 2,
    icon: IconName.cloudadd1,
    title: 'about.easy.title',
    content: 'about.easy.content',
  },
  {
    id: 3,
    icon: IconName.emojihappy,
    title: 'about.free.title',
    content: 'about.free.content',
  },
];
