import { FC } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { content } from './constants';
import { useTranslation } from 'react-i18next';

export const AdvantageBlock: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const { t } = useTranslation();

  return (
    <Container
      id="advantages"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        spacing={[0, 16]}
        rowSpacing={{
          xs: '77px',
          sm: '77px',
          md: '77px',
          lg: 16,
        }}
        sx={{
          mb: 14.25,

          [theme.breakpoints.down('lg')]: {
            marginBottom: '52px',
          },
        }}
      >
        {content.map(
          ({
            id,
            image,
            desktopDimensions,
            mobileImage,
            mobileDimensions,
            mobileImageStyles,
            alt,
            headerText,
            title,
            content,
            titleColor = 'primary',
            titleWidth = '498px',
            direction = 'row',
            imageSx,
          }) => (
            <Grid
              key={id}
              xs={12}
              item
              container
              justifyContent="center"
              direction={direction}
              alignItems="center"
              rowSpacing={{
                xs: '38px',
                sm: '38px',
                md: '38px',
                lg: 0,
              }}
              sx={{
                [theme.breakpoints.down('lg')]: {
                  flexDirection: 'column-reverse',
                  flexWrap: 'initial',
                },
              }}
            >
              <Grid
                item
                lg={6}
                md={12}
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                  ...imageSx,
                  [theme.breakpoints.down('lg')]: {
                    padding: 0,
                    justifyContent: 'center',
                  },
                }}
              >
                <Box
                  component="img"
                  src={matches ? image : mobileImage}
                  sx={!matches ? mobileImageStyles : {}}
                  alt={alt}
                  width={matches ? desktopDimensions.width : mobileDimensions.width}
                  height={matches ? desktopDimensions.height : mobileDimensions.height}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                container
                justifyContent="flex-end"
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    justifyContent: 'initial',
                  },
                }}
              >
                <Box>
                  <Typography color={titleColor} variant="overline">
                    {t(headerText)}
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 2,
                      mt: 1,
                      maxWidth: titleWidth,
                      [theme.breakpoints.down('lg')]: {
                        maxWidth: '100%',
                        marginBottom: '10px',
                      },
                    }}
                  >
                    {t(title)}
                  </Typography>
                  <Typography
                    color="grey.300"
                    variant="body2"
                    sx={{
                      maxWidth: '394px',
                      [theme.breakpoints.down('lg')]: {
                        maxWidth: '100%',
                      },
                    }}
                  >
                    {t(content)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    </Container>
  );
};
