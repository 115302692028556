import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  ClickAwayListenerProps,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import ReactGA from 'react-ga4';
import { Trans, useTranslation } from 'react-i18next';
import { isIOS, isMacOs } from 'react-device-detect';
import { ChevronRight as ChevronRightIcon, Menu as MenuIcon } from '@mui/icons-material';
import { links } from '../constants';
import { LangSelect } from '../LangSelect';

const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;

export const MainBlockMobile: FC = () => {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  const handleClickAway = useCallback<ClickAwayListenerProps['onClickAway']>(() => {
    if (open) {
      handleDrawerClose();
    }
  }, [open]);

  const { t } = useTranslation();

  const getStartLink = useMemo(() => {
    return isMacOs || isIOS ? process.env.REACT_APP_APP_STORE : process.env.REACT_APP_PLAY_MARKET;
  }, []);

  const handleMobileClick = useCallback(() => {
    if (MEASUREMENT_ID) {
      const actionLabel = isMacOs || isIOS ? 'APP_STORE' : 'PLAY_MARKET';
      ReactGA.event({
        category: 'Mobile',
        action: `Click app button on ${actionLabel}`,
        label: getStartLink,
      });
    }
  }, [getStartLink]);

  return (
    <Fragment>
      <AppBar
        position="static"
        sx={{
          bgcolor: 'background.default',
          background:
            'linear-gradient(124deg, #F5F3FF 8.33%, rgba(245, 243, 255, 0.00) 22.92%, #FFF6FA 40.10%, rgba(255, 246, 250, 0.50) 56.25%, rgba(226, 245, 255, 0.00) 79.77%, #E2F5FF 98.32%)',
          height: '56px',
          boxShadow:
            '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
        }}
      >
        <Container>
          <Toolbar
            sx={{
              paddingLeft: 0,
              paddingRight: '4px',
            }}
          >
            <Box component="img" src="/images/mobile-logo.svg" alt="mobile-logo" />
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon
                      sx={{
                        color: 'grey.400',
                      }}
                    />
                  </IconButton>
                  <Drawer
                    sx={{
                      '& .MuiDrawer-paper': {
                        maxWidth: '250px',
                        width: '100%',
                      },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={open}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                      }}
                    >
                      <IconButton onClick={handleDrawerClose}>
                        <ChevronRightIcon />
                      </IconButton>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <LangSelect />
                      </Box>
                    </Box>

                    <Divider />
                    <List>
                      {links.map(({ id, label, href }) => (
                        <ListItem
                          key={id}
                          component="a"
                          sx={{
                            color: 'text.primary',
                          }}
                          disablePadding
                          onClick={handleDrawerClose}
                          href={`#${href}`}
                        >
                          <ListItemButton>{t(label)}</ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </Box>
              </ClickAwayListener>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container>
        <Grid
          container
          sx={{
            mt: 0,
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
            },
          }}
          rowSpacing="30px"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                '&.MuiGrid-root': {
                  pt: 2,
                },
              },
            }}
          >
            <Box>
              <Typography variant="overline" color="secondary">
                {t('headerContent.headerText')}
              </Typography>

              <Typography
                variant="h1"
                sx={{
                  mt: 1,

                  [theme.breakpoints.down('md')]: {
                    mt: 0.5,
                  },
                }}
              >
                <Trans
                  i18nKey="headerContent.titleMobile"
                  components={{
                    typography: (
                      <Typography
                        variant="h1"
                        color="primary"
                        sx={{
                          display: 'inline',
                        }}
                      />
                    ),
                  }}
                />
              </Typography>

              <Box
                sx={{
                  mt: 3,
                  textAlign: 'start',
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'center',
                    mt: 2,
                  },
                }}
              >
                <Button
                  variant="contained"
                  target="_blank"
                  component={Link}
                  onClick={handleMobileClick}
                  href={getStartLink}
                  sx={{
                    '&.MuiButton-root': {
                      bgcolor: 'common.black',
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                      },
                    },
                  }}
                >
                  {t('button.getStarted')}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                '&.MuiGrid-root': {
                  pt: 3,
                },
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: '324px',
                height: '370px',
              }}
              src="/images/phone-mobile.png"
              alt="phone-mobile"
            />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};
