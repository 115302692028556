import { FC } from 'react';
import { Box, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Footer: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '86px',
        bgcolor: 'common.black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
          height: '64px',
        },
      }}
      component="footer"
    >
      <Box
        sx={{
          typography: 'body1',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',

          [theme.breakpoints.down('lg')]: {
            '& > :not(style)': {
              fontSize: '14px',
            },
          },
          '& > .MuiLink-root': {
            mx: 2.5,

            [theme.breakpoints.down('lg')]: {
              mx: '14px',
            },
          },
        }}
      >
        <Link href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
          {t('navigation.contactUs')}
        </Link>
        <Link target="_blank" href={process.env.REACT_APP_POLICY}>
          {t('navigation.privacyPolicy')}
        </Link>
        <Link target="_blank" href={process.env.REACT_APP_TERMS}>
          {t('navigation.termsOfUse')}
        </Link>
      </Box>
    </Box>
  );
};
