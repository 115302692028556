import { ITranslateResource } from 'i18n/types';

const uaTranslations: ITranslateResource = {
  navigation: {
    advantages: 'Переваги',
    features: 'Особливості',
    howItWorks: 'Як це працює',
    contactUs: "Зв'яжіться з нами",
    privacyPolicy: 'Політика конфіденційності',
    termsOfUse: 'Умови використання',
  },
  headerContent: {
    headerText: 'Керуй своїм бюджетом просто',
    title: 'Плануйте витрати <br /> та контролюйте <br /> свої <typography>гроші</typography>',
    titleMobile: 'Плануйте витрати та контролюйте свої <typography>гроші</typography>',
    subTitle: 'Додаток, який допоможе вам планувати, контролювати та оптимізувати витрати',
    scanToInstainstall: 'Скануйте, щоб інсталювати',
  },
  button: {
    getStarted: 'Почати',
  },
  about: {
    functional: {
      title: 'Функціональний',
      content: 'Корисні функції для оптимізації вашого бюджету',
    },
    easy: {
      title: 'Простий',
      content: 'Простий та зрозумілий у використанні',
    },
    free: {
      title: 'Безкоштовний',
      content: 'Просто спробуйте та насолоджуйтесь!',
    },
  },
  advantage: {
    users: {
      headerText: 'Твої люди',
      title: 'Створюйте спільні бюджети з родиною та друзями',
      subTitle:
        'У вас є можливість створювати будь-яку кількість бюджетів і додавати скільки завгодно людей! Просто додайте учасників і разом керуйте бюджетом',
    },
    limits: {
      headerText: 'Жодних лімітів',
      title: 'Будь-яка кількість гаманців та категорій',
      subTitle:
        'Створюйте стільки гаманців та категорій, скільки вам потрібно. Встановлюйте бюджет на категорії, щоб планувати та контролювати свої витрати.',
    },
  },
  features: {
    headerText: 'Більше корисних функцій',
    title: 'Зручні функції для вас',
    subTitle:
      'Додаток Right Budget створено для полегшення управлінням особистими та спільними бюджетами. Його основні функції:',
    items: {
      1: {
        title: 'Необмежені рахунки',
        subTitle: 'Безліміт на створення гаманців',
      },
      2: {
        title: 'Необмежені категорії',
        subTitle: 'Створюйте бюджет для кожної категорії',
      },
      3: {
        title: 'Спільні бюджети',
        subTitle: 'Спільні бюджети з необмеженою кількістю користувачів',
      },
      4: {
        title: 'Списки покупок',
        subTitle: 'Ведіть списки покупок разом',
      },
      5: {
        title: 'Статистика та історія',
        subTitle: 'Проста статистика та історія транзакцій',
      },
      6: {
        title: 'Прості робочі області',
        subTitle: 'Особисті та спільні робочі простори',
      },
    },
  },
  steps: {
    title: 'Твій шлях',
    subTitle: 'Три прості кроки до складання бюджету',
    stepper: {
      1: {
        title: 'Плануйте свій бюджет',
        subTitle: 'Створюйте необхідні категорії та плануйте витрати на кожен місяць',
      },
      2: {
        title: 'Контролюйте витрати',
        subTitle: 'Слідкуйте за транзакціями в історії та будьте в курсі руху ваших грошей',
      },
      3: {
        title: 'Аналізуйте та покращуйте',
        subTitle: 'Дивіться статистику за місяці та роки та оптимізуйте витрати',
      },
    },
  },
  cta: {
    title: 'Right Budget абсолютно безкоштовний',
    content:
      'Ми допомагаємо вам планувати та контролювати свої витрати, а не додавати нові. Тому додаток є безкоштовним для всіх користувачів',
  },
};

export default uaTranslations;
