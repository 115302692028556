import { FC, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isIOS, isMacOs } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;

export const CTABlock: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const getStartLink = useMemo(() => {
    return isMacOs || isIOS ? process.env.REACT_APP_APP_STORE : process.env.REACT_APP_PLAY_MARKET;
  }, []);

  const handleFooterClick = useCallback(() => {
    if (MEASUREMENT_ID) {
      const actionLabel = isMacOs || isIOS ? 'APP_STORE' : 'PLAY_MARKET';
      ReactGA.event({
        category: 'Footer',
        action: `Click app button on ${actionLabel}`,
        label: getStartLink,
      });
    }
  }, [getStartLink]);

  return (
    <Container
      sx={{
        [theme.breakpoints.down('lg')]: {
          padding: 0,
        },
      }}
    >
      <Box
        sx={{
          height: (theme) => theme.spacing(58.75),
          bgcolor: 'common.black',
          borderRadius: '24px',
          mb: 12.5,
          paddingLeft: '105px',
          paddingRight: '107px',

          [theme.breakpoints.down('lg')]: {
            m: 0,
            padding: '0 14px',
            borderRadius: 0,
            height: '376px',
          },
        }}
      >
        <Grid
          container
          sx={{
            height: '100%',
          }}
        >
          <Grid
            item
            md={12}
            lg={7}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',

              [theme.breakpoints.down('lg')]: {
                alignItems: 'center',
              },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                letterSpacing: '0.5px',
                background:
                  'linear-gradient(90deg, #A8D7FC 11.81%, #A28BDC 56.14%, #FF5790 97.28%)',
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                mb: 1,

                [theme.breakpoints.down('lg')]: {
                  textAlign: 'center',
                  lineHeight: 'normal',
                  mb: 2,
                },
              }}
            >
              {t('cta.title')}
            </Typography>
            <Typography
              variant={!matches ? 'body2' : 'body1'}
              color="grey.200"
              sx={{
                maxWidth: '483px',
                mb: '27px',

                [theme.breakpoints.down('lg')]: {
                  textAlign: 'center',
                  lineHeight: 'normal',
                  maxWidth: '100%',
                  mb: '32px',
                },
              }}
            >
              {t('cta.content')}
            </Typography>
            <Box
              sx={{
                [theme.breakpoints.down('lg')]: {
                  textAlign: 'center',
                },
              }}
            >
              <Button
                variant="contained"
                target="_blank"
                component={Link}
                href={getStartLink}
                onClick={handleFooterClick}
                color="primary"
                sx={{
                  minWidth: '196px',
                }}
              >
                {t('button.getStarted')}
              </Button>
            </Box>
          </Grid>
          {matches && (
            <Grid item xs={5} container justifyContent="flex-end" alignItems="center">
              <Box
                component="img"
                src="/images/budget-unsplash.png"
                alt="budget-unsplash"
                width={391}
                height={290}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};
