export const links = [
  {
    id: 1,
    label: 'navigation.advantages',
    href: 'advantages',
  },
  {
    id: 2,
    label: 'navigation.features',
    href: 'features',
  },
  {
    id: 3,
    label: 'navigation.howItWorks',
    href: 'howItWorks',
  },
];
