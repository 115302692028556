import { IconName } from 'ui';

export const featuresItems: {
  id: number;
  icon: IconName;
  title: string;
  subTitle: string;
}[] = [
  {
    id: 1,
    icon: IconName.cards,
    title: 'features.items.1.title',
    subTitle: 'features.items.1.subTitle',
  },
  {
    id: 2,
    icon: IconName.category,
    title: 'features.items.2.title',
    subTitle: 'features.items.2.subTitle',
  },
  {
    id: 3,
    icon: IconName.convertshape2,
    title: 'features.items.3.title',
    subTitle: 'features.items.3.subTitle',
  },
  {
    id: 4,
    icon: IconName.note,
    title: 'features.items.4.title',
    subTitle: 'features.items.4.subTitle',
  },
  {
    id: 5,
    icon: IconName.chart21,
    title: 'features.items.5.title',
    subTitle: 'features.items.5.subTitle',
  },
  {
    id: 6,
    icon: IconName.copysuccess,
    title: 'features.items.6.title',
    subTitle: 'features.items.6.subTitle',
  },
];
