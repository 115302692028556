import { TI18nResource } from 'i18n/types';

import enTranslations from './en';
import uaTranslations from './ua';

const resources: TI18nResource = {
  en: {
    translation: enTranslations,
  },
  ua: {
    translation: uaTranslations,
  },
};

export default resources;
