import { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import ReactGA from 'react-ga4';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import theme from 'theme';

// translations
import 'i18n';

import {
  AboutBlock,
  AdvantageBlock,
  CTABlock,
  FeaturesBlock,
  Footer,
  MainBlock,
  StepsBlock,
} from 'components';
import { deviceType } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;

function App(): JSX.Element {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (MEASUREMENT_ID) {
      ReactGA.set(location);
      ReactGA.send({
        hitType: 'pageview',
        title: 'app_navigate_pageview',
        page: location.pathname,
        deviceType,
      });
    }
  }, [location]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        {pathname !== '/en' ? (
          <Helmet>
            <title>Додаток для ведення бюджету Right Budget | Трекер бюджету</title>
            <meta
              name="description"
              content="Безкоштовний додаток для ведення сімейного бюджету Right Budget допоможе правильно вести бюджет. Фінансове планування з додатком для контролю витрат це легко"
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>Budgeting application Right Budget | Budget tracker</title>
            <meta
              name="description"
              content="Right Budget, a free family budgeting application, will help you manage your budget correctly. Financial planning is easy with the spending control app"
            />
          </Helmet>
        )}
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <MainBlock />

        <AboutBlock />

        <AdvantageBlock />

        <FeaturesBlock />

        <StepsBlock />

        <CTABlock />

        <Footer />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
